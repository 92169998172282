import React, { useState } from "react"
import styles from "./contact-info.module.css"
import LocationOnSharpIcon from "@material-ui/icons/LocationOnSharp"
import CallSharpIcon from "@material-ui/icons/CallSharp"
import EmailIcon from "@material-ui/icons/Email"

const ContactInfo = () => {
  return (
    <div className={styles.container}>
      <div className={styles.loginCard}>
        <div className={styles.login}>
          <div className={styles.header}>
            <span className={styles.highlight}>Contact</span> Us!
          </div>
          <div className={styles.cSection}>
            <LocationOnSharpIcon fontSize="large" />
            <p className={styles.cInfo}>
              Red Diamond Corporate Centre <br />
              218 Export Blvd, Mississauga, ON <br />
              Suite 303
            </p>
          </div>
          <div className={styles.cSection}>
            <EmailIcon fontSize="large" />
            <p className={styles.cInfo}>
              <a href="mailto:info@finsimpl.com">raja@finsimpl.com</a>
            </p>
          </div>
          <div className={styles.cSection}>
            <CallSharpIcon fontSize="large" />
            <p className={styles.cInfo}>+1 (365) 778-1649</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactInfo
