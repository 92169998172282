import React from "react"

import Layout from "../components/layout"
import Menu from "../components/menu"
import Footer from "../components/footer"
import ContactInfo from "../components/contact-info"

const ContactPage = props => (
  <Layout type={1}>
    <Menu type={2} btype={3} />
    <ContactInfo />
    <Footer type={1} />
  </Layout>
)

export default ContactPage
